<template>
  <div
    v-if="
      getUser.role.slug === 'super-administrateur' ||
      getUser.role.slug === 'assistante-administrative'
    "
  >
    <div
      v-if="isLoading"
      class="d-flex justify-content-center mb-1"
      style="padding-top: 30vh"
    >
      <b-spinner
        variant="info"
        style="width: 3rem; height: 3rem;"
        class="mr-1"
      />
    </div>
    <div v-else>
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <b-card>
            <b-row>
              <!-- User Info: Left col -->
              <b-col
                cols="12"
                xl="6"
                class="d-flex justify-content-between flex-column"
              >
                <!-- User Avatar & Action Buttons -->
                <div class="d-flex justify-content-start">
                  <b-avatar
                    :text="avatarText(
                      `${wallet.employee.full_name}`
                    )
                    "
                    :src="wallet.employee.profile_image"
                    size="50px"
                    rounded
                  />
                  <div class="d-flex flex-column ml-1">
                    <div class="mb-1">
                      <h4 class="mb-0">
                        {{ wallet.employee.full_name }}
                      </h4>
                      <span class="card-text">{{ wallet.employee.email }}</span>
                    </div>
                  </div>
                </div>
                <!-- User Stats -->
              </b-col>

              <!-- Right Col: Table -->
              <b-col
                cols="12"
                xl="6"
              >
                <table class="mt-2 mt-xl-0 w-100">
                  <tr>
                    <th class="pb-50">
                      <feather-icon
                        icon="PhoneIcon"
                        class="mr-75"
                      />
                      <span class="font-weight-bold font-medium-1">Téléphone</span>
                    </th>
                    <td class="pb-50 text-capitalize font-medium-1">
                      {{ wallet.employee.phone_number }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon
                        icon="MapPinIcon"
                        class="mr-75"
                      />
                      <span class="font-weight-bold font-small-4">Adresse</span>
                    </th>
                    <td class="pb-50 text-capitalize font-small-4">
                      {{ wallet.employee.address }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon
                        icon="CreditCardIcon"
                        class="mr-75 text-info"
                      />
                      <span class="font-weight-bold text-info font-small-4">Numéro mobile Money</span>
                    </th>
                    <td class="pb-50 text-capitalize text-info font-small-4">
                      {{ wallet.employee.mtn_number }}
                    </td>
                  </tr>
                  <tr>
                    <th class="pb-50">
                      <feather-icon
                        icon="CreditCardIcon"
                        class="mr-75 text-info"
                      />
                      <span class="font-weight-bold text-info font-small-4">Numéro Flooz</span>
                    </th>
                    <td class="pb-50 text-capitalize text-info font-small-4">
                      {{ wallet.employee.flooz_number }}
                    </td>
                  </tr>
                </table>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
          <b-card
            no-body
            class="border-primary"
          >
            <b-card-body>
              <div class="mb-0 font-medium-3">
                Solde: <span
                  :class="wallet.balance > 0 ? 'text-success' : 'text-danger'
                  "
                >
                  XOF {{ wallet.balance }}
                </span>

              </div>
              <div class="mb-0 font-medium-3">
                Programme YLOMI: <span
                  :class="wallet.employee.is_in_ylomi_program ? 'text-success' : 'text-warning'
                  "
                >
                  {{ wallet.employee.is_in_ylomi_program ? 'Participe' : 'Ne participe pas' }}

                </span>
              </div>

            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

      <b-card>
        <b-row>
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-2"
          >
            <b-button
              variant="outline-info"
              @click="showWalletOperationModal('withdraw')"
            >
              <span class="text-nowrap font-medium-1">Faire un retrait</span>
            </b-button>

          </b-col>
          <b-col
            cols="12"
            md="3"
            class="mb-2"
          >
            <b-button
              variant="outline-success"
              @click="showWalletOperationModal('deposit')"
            >
              <span class="text-nowrap font-medium-1">Faire un dépôt</span>
            </b-button>

          </b-col>
        </b-row>

        <b-table
          ref="refInvoiceListTable"
          :items="walletLogs"
          responsive
          :fields="tableWalletLogsColumns"
          primary-key="id"
          show-empty
          empty-text="Aucune opération effectuée."
          class="position-relative"
        >
          <!-- Column: Issued Date -->
          <template #cell(balance_before_operation)="data">
            <span
              class="font-small-3"
              :class="data.item.balance_before_operation > 0 ? 'text-info' : 'text-danger'"
            >
              XOF {{ data.item.balance_before_operation }}
            </span>
          </template>
          <template #cell(amount)="data">
            <span class="text-info font-small-3">
              XOF {{ data.item.amount }}
            </span>
          </template>
          <template #cell(balance_after_operation)="data">
            <span
              class="text-info font-small-3"
              :class="data.item.balance_after_operation > 0 ? 'text-info' : 'text-danger'"
            >
              XOF {{ data.item.balance_after_operation }}
            </span>
          </template>
          <template #cell(operation_date)="data">
            <span class=" font-small-3">
              {{
                moment(data.item.operation_date).locale("fr")
                  .format("llll").split('00:00')[0]

              }}
            </span>
          </template>

          <template #cell(operation_type)="data">
            <b-badge
              pill
              :variant="`light-${resolveOrderStatusVariant(
                data.item.operation_type
              )}`"
              class="text-capitalize font-small-3"
            >
              {{ resolveStatusText(data.item.operation_type) }}
            </b-badge>
          </template>
          <template #cell(user)="data">
            <span class="text-info font-small-3">
              {{ data.item.operation_type === 'deposit' ? 'Le système' : data.item.user === null ? "" :
                data.item.user.full_name }}
            </span>
          </template>
        </b-table>
      </b-card>
    </div>
    <b-modal
      id="modal-new-wallet-operation"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      no-close-on-backdrop
      no-close-on-esc
      title="Confirmation du paiement d'employé"
    >
      <validation-observer
        #default="{}"
        ref="paymentForm"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          autocomplete="off"
          @reset.prevent="hideModal"
          @submit.prevent="applyEmployeeWalletOperationAction"
        >
          <!-- Email -->

          <validation-provider
            v-if="payload.operation_type == 'withdraw'"
            #default="{ errors }"
            name="withdraw"
            rules="required"
          >
            <b-form-group
              label="Type de retrait"
              label-for="withdraw_type"
              :state="errors.length > 0 ? false : null"
            >
              <v-select
                id="withdraw_type"
                v-model="payload.withdraw_type"
                :reduce="(item) => item.value"
                label="name"
                :options="[{ name: 'Payement de salaire ', value: 1 }, { name: 'Autres', value: 2 }]"
              />
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group
            v-if="payload.withdraw_type == 1"
            label="Choisissez le salaire à payé"
            label-for="client"
          >
            <validation-provider
              #default="{ errors }"
              name="client"
              rules="required"
            >
              <v-select
                v-model="selected_payement"
                :reduce="(item) => item"
                label="customKey"
                :options="payementInformations"
              >
                <span
                  v-if="isUnpaid == false && payementInformations.length == 0"
                  slot="no-options"
                  @click="$refs.select.open = false"
                >
                  Aucun salaire impayé à cet employé
                </span>
                <template
                  #option="{
                    full_name,
                    year,
                    month_salary,
                    employee_salary_amount,
                    is_business_order
                  }"
                >
                  <b-row>
                    <b-col md="9">
                      <b-row>
                        <b-col>
                          <span class="text-white">
                            Employeur: {{ full_name }}
                          </span>
                        </b-col>
                        <b-col>
                          <span class="text-white">
                            Salaire à payer: {{ employee_salary_amount }}
                          </span>
                        </b-col>
                      </b-row>
                      <b-row class="">
                        <b-col>
                          <span class="text-white">
                            Salaire du mois de {{ month_salary }} {{ year }}
                          </span>
                        </b-col>
                      </b-row>
                      <b-row class="">
                        <b-col>
                          <span class="text-success">
                            Prestation: {{ is_business_order ? 'Business' : 'Récurente' }} 
                          </span>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </template>
              </v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <validation-provider
            v-if="payload.withdraw_type == 1"
            #default="{ errors }"
            name="withdraw"
            rules="required"
          >
            <b-form-group
              label="Tranfert automatique"
              label-for="make_transfer"
              :state="errors.length > 0 ? false : null"
            >
              <v-select
                id="make_transfer"
                v-model="payload.make_transfer"
                :reduce="(item) => item.value"
                label="name"
                :options="[{ name: 'Oui', value: 1 }, { name: 'Non', value: 0 }]"
              />
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            v-if="payload.operation_type == 'withdraw' && payload.make_transfer == 1"
            #default="{ errors }"
            name="transfer_method"
            rules="required"
          >
            <b-form-group
              label="Moyen de transfert"
              label-for="transfer_method"
              :state="errors.length > 0 ? false : null"
            >
              <v-select
                id="transfer_method"
                v-model="payload.transfer_method"
                :reduce="(item) => item.value"
                label="name"
                :options="[{ name: 'MTN', value: 1 }, { name: 'MOOV', value: 2 }]"
              />
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            v-if="payload.withdraw_type == 2 || payload.operation_type == 'deposit'"
            #default="{ errors }"
            designation="designation"
            rules="required"
          >
            <b-form-group
              label="Libellé"
              label-for="designation"
            >
              <b-form-input
                id="designation"
                v-model="payload.designation"
                type="text"
                :state="errors.length > 0 ? false : null"
                trim
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider
            v-if="payload.withdraw_type == 2 || payload.operation_type == 'deposit'"
            #default="{ errors }"
            designation="amount"
            rules="required"
          >
            <b-form-group
              label="Montant"
              label-for="amount"
            >
              <b-form-input
                id="amount-add"
                v-model="amount_add"
                type="number"
                :state="errors.length > 0 ? false : null"
                trim
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider
            v-if="payload.withdraw_type == 1"
            #default="{ errors }"
            designation="amount"
            rules="required"
          >
            <b-form-group
              label="Montant"
              label-for="amount"
            >
              <b-form-input
                id="amount-add"
                v-model="amount_add"
                type="number"
                :state="errors.length > 0 ? false : null"
                trim
                readonly
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              :disabled="isPaymentLoading"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <div v-if="isPaymentLoading">
                <span> Chargement ... </span>
                <b-spinner small />
              </div>
              <span v-else>Confirmer</span>
            </b-button>
            <b-button
              type="reset"
              variant="outline-secondary"
            >
              Annuler
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-modal>

  </div>
  <div v-else>
    <not-authorized />
  </div>
</template>

<script>
import {
  BAlert,
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BForm,
  BFormInput,
  BFormGroup,
  BAvatar,
  BLink,
  BBadge,
  BFormTextarea,
  BCardHeader,
  BCardBody,
  BSpinner,
  BTab,
  BTabs,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { mapActions, mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required, alphaNum, email } from '@validations'

export default {
  components: {
    vSelect,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BSpinner,
    BForm,
    BFormInput,
    BFormGroup,
    BCardHeader,
    BCardBody,
    BAlert,
    BRow,
    BCol,
    BTable,
    BAvatar,
    BLink,
    BBadge,
    BTab,
    BTabs,
    BFormTextarea,
  },
  data() {
    return {
      amount_add: null,
      is_business_order: null,
      selected_payement: '',
      isUnpaid: true,
      payementInformations: [],
      wallet: null,
      walletLogs: null,
      payload: {
        designation: '',
        amount: '',
        operation_type: '',
        transfer_method: '',
        make_transfer: '',
      },
      required,
      alphaNum,
      isPaymentLoading: false,
      email,
      isLoading: true,
      tableWalletLogsColumns: [
        {
          key: 'operation_date', label: "Date l'opération", sortable: true, class: 'font-medium-2',
        },
        {
          key: 'operation_type', label: "Type d'opération", sortable: true, class: 'font-small-4',
        },
        {
          key: 'trace', label: 'Description', sortable: true, class: 'font-small-4',
        },
        {
          key: 'amount', label: 'Montant', sortable: true, class: 'font-small-4',
        },
        {
          key: 'balance_before_operation', label: "Montant avant l'opération", sortable: true, class: 'font-small-4',
        },
        {
          key: 'balance_after_operation', label: 'Solde', sortable: true, class: 'font-small-4',
        },
        {
          key: 'user', label: 'Effectué par', sortable: true, class: 'font-small-4',
        },

      ],
      avatarText,
    }
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
  },
  watch: {
    selected_payement(val) {
      this.amount_add = val.employee_salary_amount;
      this.is_business_order = val.is_business_order
    },

  },
  created() {
    this.applyGetEmployeesWalletDetailsAction(this.$route.params.id)
  },
  methods: {
    ...mapActions('professionals', [
      'getEmployeesWalletDetailsAction',
      'getEmployeesWalletLogsAction',
      'employeeWalletOperationAction', 'employeeSalaryUnpaidAction', 'employeeTransfertOperationAction',
    ]),
    applyGetEmployeeSalaryUnpaidAction(id) {
      this.employeeSalaryUnpaidAction(id)
        .then(res => {
          console.log('///', res)
          this.isUnpaid = false
          this.payementInformations = []
          res.data.forEach(element => {
            element.customKey = `${element.month_salary} ${element.year}`

            this.payementInformations.push(element)
          })

          console.log(this.payementInformations)
        })
        .catch(() => {
          this.isUnpaid = true
        })
    },

    applyEmployeeWalletOperationAction() {
      this.$refs.paymentForm.validate().then(success => {
        if (success) {
          (this.isPaymentLoading = true)
          if (this.selected_payement) {
            this.payload.payment_id = this.selected_payement.id
          }
          this.payload.amount = this.amount_add
          this.payload.is_business_order = this.is_business_order ? 1 : 0

          if (this.payload.withdraw_type == 1) {
            this.employeeTransfertOperationAction({ id: this.wallet.employee.id, payload: this.payload })
              .then(response => {
                this.isPaymentLoading = false
                this.applyGetEmployeesWalletDetailsAction(this.$route.params.id)
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Succès',
                      icon: 'CheckIcon',
                      text: response.message,
                      variant: 'success',
                    },
                  },
                  {
                    position: 'top-center',
                  },
                )
                this.hideModal()
              })
              .catch(error => {
                console.log(error)
                this.isPaymentLoading = false
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Erreur',
                      icon: 'AlertTriangleIcon',
                      text: error.response.data.message,
                      variant: 'danger',
                    },
                  },
                  {
                    position: 'top-center',
                  },
                )
              })
          } else {
            this.employeeWalletOperationAction({ id: this.wallet.employee.id, payload: this.payload })
              .then(response => {
                this.isPaymentLoading = false
                this.applyGetEmployeesWalletDetailsAction(this.$route.params.id)
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Succès',
                      icon: 'CheckIcon',
                      text: response.message,
                      variant: 'success',
                    },
                  },
                  {
                    position: 'top-center',
                  },
                )
                this.hideModal()
              })
              .catch(error => {
                console.log(error)
                this.isPaymentLoading = false
                this.$toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: 'Erreur',
                      icon: 'AlertTriangleIcon',
                      text: error.response.data.message,
                      variant: 'danger',
                    },
                  },
                  {
                    position: 'top-center',
                  },
                )
              })
          }
        }
      })
    },

    applyEmployeeTransfertOperationAction() {
      this.$refs.paymentForm.validate().then(success => {
        if (success) {
          (this.isPaymentLoading = true)
          if (this.selected_payement) {
            this.payload.payment_id = this.selected_payement.id
          }
          this.payload.amount = this.amount_add
          this.payload.amount = this.amount_add

          this.employeeTransfertOperationAction({ id: this.wallet.employee.id, payload: this.payload })
            .then(response => {
              this.isPaymentLoading = false
              this.applyGetEmployeesWalletDetailsAction(this.$route.params.id)
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Succès',
                    icon: 'CheckIcon',
                    text: response.message,
                    variant: 'success',
                  },
                },
                {
                  position: 'top-center',
                },
              )
              this.hideModal()
            })
            .catch(error => {
              console.log(error)
              this.isPaymentLoading = false
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Erreur',
                    icon: 'AlertTriangleIcon',
                    text: error.response.data.message,
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-center',
                },
              )
            })
        }
      })
    },
    applyGetEmployeesWalletDetailsAction(id) {
      this.getEmployeesWalletDetailsAction(id)
        .then(response => {
          console.log('----------', response)
          this.isLoading = false
          this.wallet = response.data
          this.applyGetEmployeeSalaryUnpaidAction(this.wallet.employee.id)
        })
        .catch(() => {
          this.isLoading = false
        })

      this.getEmployeesWalletLogsAction(id)
        .then(response => {
          console.log('----------', response)
          this.isLoading = false
          this.walletLogs = response.data
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    hideModal() {
      this.$bvModal.hide('modal-new-wallet-operation')
      this.selected_payement = []
      this.payload = {
        designation: '',
        amount: '',
        operation_type: '',
        withdraw_type: '',
        payment_id: '',
        transfer_method: '',
        make_transfer: '',
      }
      this.amount_add = ''
    },

    resolveOrderStatusVariant(text) {
      if (text === 'deposit') {
        return 'success'
      }
      return 'danger'
    },
    resolveStatusText: text => {
      if (text === 'deposit') {
        return 'Dépôt'
      }
      return 'Retrait'
    },
    showWalletOperationModal(operation_type) {
      this.$bvModal.show('modal-new-wallet-operation')
      this.payload.operation_type = operation_type
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
